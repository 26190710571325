var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[(_vm.userForm)?_c('div',{staticClass:"form-container"},[_c('h2',[_vm._v("Buat User")]),_c('form',{staticClass:"form"},[_c('div',{staticClass:"input-item"},[_c('label',{staticClass:"form__label",class:{
            error: _vm.$v.form.name.$error,
          },attrs:{"for":"client-name"}},[_vm._v(" Nama ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.name.$model),expression:"$v.form.name.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorborder: _vm.$v.form.name.$error,
          },attrs:{"type":"text","name":"name","id":"name"},domProps:{"value":(_vm.$v.form.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-item"},[_c('label',{staticClass:"form__label",class:{
            error: _vm.$v.form.email.$error,
          },attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorborder: _vm.$v.form.email.$error,
          },attrs:{"type":"email","name":"email","id":"email"},domProps:{"value":(_vm.$v.form.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-item"},[_c('label',{staticClass:"form__label",class:{
            error: _vm.$v.form.password.$error,
          },attrs:{"for":"password"}},[_vm._v(" Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model",modifiers:{"trim":true}}],staticClass:"form__input",class:{
            errorborder: _vm.$v.form.password.$error,
          },attrs:{"type":"password","name":"password","id":"password"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.is_admin),expression:"form.is_admin"}],staticStyle:{"margin-right":"0.5em"},attrs:{"type":"checkbox","name":"is_admin","id":"is_admin"},domProps:{"checked":Array.isArray(_vm.form.is_admin)?_vm._i(_vm.form.is_admin,null)>-1:(_vm.form.is_admin)},on:{"change":function($event){var $$a=_vm.form.is_admin,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "is_admin", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "is_admin", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "is_admin", $$c)}}}}),_c('label',{attrs:{"for":"is_admin"}},[_vm._v(" Admin ")])])]),_c('div',{staticClass:"btn-container"},[_c('button',{staticClass:"btn-discard",on:{"click":_vm.SET_USER_IS_OPEN}},[_vm._v("Tutup")]),_c('div',[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editUser.status),expression:"editUser.status"}],staticClass:"btn-draft",on:{"click":_vm.deleteItem}},[_vm._v(" Hapus ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editUser.status),expression:"!editUser.status"}],staticClass:"btn-save",on:{"click":_vm.save}},[(_vm.isLoading)?_c('clip-loader',{attrs:{"size":"12px","color":"#fff"}}):[_vm._v(" Buat User ")]],2),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.editUser.status),expression:"editUser.status"}],staticClass:"btn-save",on:{"click":_vm.update}},[_vm._v(" Simpan ")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }